@import "../../styles/style.scss";

.dialogStyle {
  text-align: center;
  :global .MuiDialog-paper {
    width: 100%;
    @media #{$break-sm} {
      width: unset;
      min-width: 400px !important;
    }
  }
}

.center {
  margin: 0 auto;
}

.dialogTitle {
  padding: 40px 50px 0px 50px;
  color: $color-main-dark;
}

.dialogTitleImage {
  width: 80px;
}

.dialogContentText {
  font-size: 18px !important;
  color: $color-black !important;
  padding-top: 0px !important;
  white-space: pre-wrap;
}

.dialogActions {
  padding: 20px 50px 20px 50px;
  margin: 0 auto;
}
