.profile {
    margin-top: 10px;
    padding: 20px 50px;
  }
  .profile_head {
    text-align: center;
  }
  .heading_pro {
    color: #0d56a4;
    font-weight: 600;
  }

.from_inputs{
    margin: auto;
    width: 50%;
    padding: 50px 0px;
}
.add_btn{
    margin-top: 20px;
    display: flex;
    justify-content: end;
}
.add_item{
    margin-right: 5px;
  border: none;
  background-color: #0D56A4;
  color: white;
  padding: 7px 20px;
  border-radius: 8px;
  font-size: 18px;
}

.outer{
    padding: 10px 20px;
}
.heading{
    padding: 10px;
}
.news_head{
    font-size: 30px;
    border-radius: 5px;
    color: #0D56A4;
    padding: 5px;
    font-weight: 600;
}

.add_btn{
    text-align: end;
    margin-top:20px;
}
.add_item{
    margin-right: 5px;
    border: none;
    background-color: #0D56A4;
    color: white;
    padding: 7px 15px;
    border-radius: 8px;
    font-size: 18px;
    font-weight: 700;
}
.edit_btn{
    margin-right: 10px;
    margin-top: 10px;
    // border: none;
    // background-color: #0D56A4;
    color: #0D56A4;
    // padding: 7px 10px;
    // border-radius: 8px;
    font-size: 22px;
    font-weight: 700;
}
.delete_btn{
    margin-left: 5px;
    margin-top: 10px;
    color: #d30707;
    font-size: 22px;
    cursor: pointer;
    font-weight: 700;
}
.active_btn{
    font-size: 30px !important;
    color: green !important;
    cursor: pointer;
}
.del_btn{
    margin-right: 10px;
    border: none;
    background-color: #0D56A4;
    color: white;
    padding: 7px 10px;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 700;
    
}
.inactive_btn{
    font-size: 30px !important;
    color: #d33303 !important;
    cursor: pointer;
}

.blog_start{
    display: flex;
    margin-top: 30px;
    padding: 0px 60px;
}
.blog_item{
    width: 80%;
    // margin: auto;
}
.blog_items{
    width: 80%;
}
.blog_images{
    display: block;
}
.blox_box{
    margin-top: 50px;
}
.blog_heading{
    color: #002776;
    cursor: pointer;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 20px;
}
.blog_image{
    margin-top: 20px;
    display: flex;
}
.blog_desc{
    margin-top: 30px;
    margin-left: 30px;
    width: 50%;
}
.blog_descs{
    width: 70%;
    margin-top: 20px;
}
.update_div{
    margin-top: 50px;
    text-align: end;
}
.update_btn{
    margin-right: 5px;
    border: none;
    background-color: #0D56A4;
    color: white;
    padding: 7px 15px;
    border-radius: 8px;
    font-weight: 600;
    font-size: 18px;
}
.blog_head{
    color: black;
    font-weight: 600;
}



.modal {
    display: flex !important;
    justify-content: center !important;
}
.modal_dialoge{
    display: flex !important;
    justify-content: center !important;
    text-align: center !important;
    // margin: auto !important;
}
.cancel_btn{
    color: #0D56A4;
    border: 3px solid #0D56A4;
    border-radius: 5px;
    padding: 5px 10px;
    font-weight: 600;
}