.job-body{
    margin-top: 30px;
  }
  .ja-form {
    display: flex;
    width: 94%;
    margin: 0 auto;
    flex-wrap: wrap;
  }
  .ja-field-container {
    margin-right: 15px;
    width: 16.53%;
  }
  .ja-field-container label {
    // display: none;
    visibility: hidden;
  }
  .ja-field select {
    font-size: 10pt;
    letter-spacing: 1.5px;
    line-height: 9.8;
    color: #15345b;
    height: 41px;
    padding: 10px;
    width: 100%;
  }
  
  .ja-button {
    float: right;
  }
  
  .ja-button {
    background: #0d56a4;
    height: 39px;
    text-align: center;
    color: #fff!important;
    height: 41px;
    padding: 10px;
    border: 1px solid #0d56a4;
    margin-top: 28px;
  }
  .ja-field input {
    font-size: 10pt;
    letter-spacing: 1.5px;
    line-height: 9.8;
    color: #15345b;
    height: 41px;
    padding: 10px;
    
  }

.ja-job-list {
    display: flex !important;
    margin: 30px auto 0 !important;
    width: 100% !important;
    flex-wrap: wrap !important;
  }
  
  // .ja-job-list {
  //   display: flex !important;
  //   margin: 30px auto 0 !important;
  //   width: 100% !important;
  //   flex-wrap: wrap !important;
  // }
  
  .job {
    width: 31% !important;
    margin-bottom: 30px !important;
    border-radius: 2px !important;
    display: flex !important;
    max-width: 263px !important;
    flex-direction: column !important;
    flex-grow: 1 !important;
    -webkit-flex-grow: 1 !important;
    transition: all .3s ease !important;
    background: #fff !important;
    padding: 20px !important;
    font-size: 14px !important;
    margin-right: 22px !important;
    margin-left: 22px !important;
    border: 1px solid #dddfe3 !important;
    border-top: 6px solid #0d56a4 !important;
  }
  .title {
    font-size: 16pt;
    font-weight: 500;
    color: #05758a;
    line-height: 1.3;
    letter-spacing: .3pt;
    margin-bottom: 15px;
  }
  .classifications {
    padding-left: 8px;
    list-style: none;
  }
  .date-posted {
    font-size: 9pt;
    color: #000;
    padding-left: 8px;
  }
  .date-posted, ul.classifications li {
    font-family: urw-din,sans-serif;
    font-weight: 400;
    line-height: 1;
    letter-spacing: .75pt;
    margin: 8px 0;
    display: block;
  }
  .summary {
    font-size: 13pt;
    color: #333;
    line-height: 1;
    margin: 8px 0;
    display: block;
    padding-left: 5px;
  }
  .view-details {
    font-size: 10pt;
    color: #05758a;
    padding-left: 8px;
    text-align: end;
    border: none;
    background-color: transparent;
  }
  
  .view-details, .summary {
    font-family: urw-din,sans-serif;
    font-weight: 400;
    letter-spacing: .75pt;
  }
  
  .powered-by-jobadder a  {
    cursor: pointer;
    color: #4183c4;
    text-decoration: none;
  }
  
  .ja-job-list {
    display: flex;
    margin: 30px auto 0;
    width: 100%;
    flex-wrap: wrap;
  }