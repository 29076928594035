@import "_theme.scss";
@import "_flex.scss";
@import "_breakpoints.scss";
@import "_form.scss";
@import "_admin.scss";

html,
body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: $font-family-primary;
}

ul, ol {
  margin: 0;
  padding: 0;

  li {
    list-style: none;
  }
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

button,
a {
  cursor: pointer;

  &:focus {
    outline: 0 !important;
  }
}

h1,h2,h3,h4,h5,h6 {
  margin: 10px 0;
}

h1 {
  font-size: 32px;

  @media #{$break-md} {
    font-size: 36px;
  }
}

h2 {
  font-size: 28px;

  @media #{$break-md} {
    font-size: 32px;
  }
}

h3 {
  font-size: 24px;

  @media #{$break-md} {
    font-size: 28px;
  }
}

h4 {
  font-size: 20px;

  @media #{$break-md} {
    font-size: 24px;
  }
}

h5 {
  font-size: 18px;

  @media #{$break-md} {
    font-size: 20px;
  }
}

h6 {
  font-size: 16px;
  line-height: 24px;
  
  @media #{$break-md} {
    font-size: 18px;
    line-height: 26px;

  }
}

p {
  font-size: 16px;
  line-height: 24px;
}

.box-style {
  background-color: #ffffff;
  border: 1px solid $color-lightGrey;
  border-radius: 4px;
}

.box-style--accent {
  border: 3px solid $color-main;
  border-width: 3px 0 0 0;
  background-color: $color-main-light;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
}

.clear-both {
  clear: both;
}

.SmallShadow {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}

.TextShadow {
  text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}

.BigShadow {
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
}

.TextHoverTransition {
  -webkit-transform: translateZ(0) scale(1, 1);
  transform: translateZ(0) scale(1, 1);
  -webkit-transition: all 0.1s linear;
  transition: all 0.1s linear;
}

.HoverTransition {
  transition: 0.25s;
  transition-timing-function: ease-out;
}

.btn-arrow-animation {
    svg {
      margin-left: 10px;
  }
  &:hover {
      svg {
          @extend .HoverTransition;
          transform:translateX(4px);
      }
  }
}

.btn-primary {
  padding: 15px 35px;
  background-color: $color-main;
  font-family: inherit;
  font-size: 20px;
  color: #ffffff;
  font-weight: 700;
  text-align: center;
  border: none;
  @extend .BigShadow;
}

.btn-primary--small {
  padding: 10px 20px;
  background-color: $color-main;
  font-family: inherit;
  font-size: 14px;
  color: #ffffff;
  font-weight: 700;
  text-align: center;
  border: none;
  margin-right: 0;
  @extend .SmallShadow;
}

.custom-button {
  border-radius: 0 !important;
  font-weight: bold !important;
  letter-spacing: 1px;
  &.capitalized {
    text-transform: capitalize !important;
    font-size: 18px;
    letter-spacing: 0.1px;
  }
}

.noselect {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.container-maxwidth {
  max-width: 960px;
  margin: 0 auto;

  @media #{$break-lg} {
    max-width: 1170px;
  }
}

.icon-horizontal-flipped {
  transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
}

.icon-vertical-flipped {
  transform: scaleY(-1);
  -moz-transform: scaleY(-1);
  -webkit-transform: scaleY(-1);
  -ms-transform: scaleY(-1);
}

.card-borderbottom {
  border-bottom: 6px solid $color-main;
  border-radius: 4px 4px 0 0;
}

.only-show-mobile {
  display: block;

  @media #{$break-md} {
    display: none;
  }
}

.only-show-desktop {
  display: none;

  @media #{$break-md} {
    display: block;
  }
}

.center {
  margin: 0 auto;
}

.video__playbutton {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: $color-accent;
  @extend .BigShadow;
  @extend .flex-center;
  position: absolute;
  z-index: 2;

  svg {
    font-size: 28px;
    color: white;
  }

  left:50%;
  transform: translate(-50%, -50%);
}

.no-radius {
  border-radius: 0 !important;
}

/* Styles for Pages with Aside 
   ========================================================================== */
.body__bg {
  background: $color-veryLightGrey;

  .body__container {
    @media #{$break-md} {
      padding: 40px 0;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
    }
  }

  .body__article {
    background-color: white;

    @media #{$break-md} {
      background-color: #ffffff;
      margin-left: 16px;
      width: 100%;
      border: 1px solid $color-lightGrey;
      border-radius: 4px;
    }
  }

  .body__article--padding {
    padding: 30px 16px;

    @media #{$break-md} {
      padding: 40px 30px 30px;
    }
  }
}


/* Material Design Overrides
   ========================================================================== */

.MuiFormControl-root {
  margin: 8px 0 !important;
}

.MuiInputBase-root {
  background-color: $color-main-light !important;
}

// modals

.MuiDialog-paper {
  margin: 20px !important;
  padding: 20px !important;
  @media #{$break-md} {
    padding: 40px !important;
  }
}
.MuiDialogTitle-root {
  padding: 16px 0 0 0 !important;
}
.MuiDialogContent-root {
  padding: 16px 0 !important;
  overflow-y: unset !important;
}
.MuiDialogActions-root {
  padding: 8px 0 !important;
}


/* Modal Styles
   ========================================================================== */
.modal-outer{
  // margin-top: 80px;
  width: 30%;
  margin: auto;
  height: 100vh;
  display: flex;
  // align-items: center;
  // display: grid;
  // place-items: center;
}
.modal-close {
  height: 30px;
  width: 30px;
  position: absolute;
  right: 1em;
  top: 1em;
  color: $color-lightGrey;
  cursor: pointer;
}

.modal-confirm {		
	color: #434e65;
}
.modal-confirm .modal-content {
  margin-top: 30px;
	padding: 20px;
	font-size: 16px;
	border-radius: 5px;
	border: none;
}
.modal-confirm h4 {
	text-align: center;
	font-size: 36px;
	margin: 10px 0;
}
.modal-confirm .form-control, .modal-confirm .btn {
	min-height: 40px;
	// border-radius: 3px; 
}
.modal-confirm .close {
	position: absolute;
	top: 15px;
	right: 35px;
	color: #fff;
  background-color: #3637F5;
  font-size:16px;
	text-shadow: none;
  border: 1px solid #fff; 
}

.bi-check-circle{
  color:#fff;
  font-size:50px;
}
.modal-confirm .close:hover {
	opacity: 0.8;
}
.modal-confirm .icon-box {
	color: #fff;		
	width: 95px;
	height: 95px;
	display: inline-block;
	border-radius: 50%;
	z-index: 9;
	border: 5px solid #fff;
	padding: 15px;
	text-align: center;
}
.modal-confirm .icon-box i {
	font-size: 64px;
	margin: -4px 0 0 -4px;
}
.modal-confirm.modal-dialog {
	margin-top: 80px;
}
.modal-confirm .btn, .modal-confirm .btn:active {
	color: #fff;
	border-radius: 4px;
	background: #eeb711 !important;
	text-decoration: none;
	transition: all 0.4s;
	line-height: normal;
	// border-radius: 30px;
	margin-top: 10px;
	padding: 6px 20px;
	border: none;
}
.modal-confirm .btn:hover, .modal-confirm .btn:focus {
	background: #eda645 !important;
	outline: none;
}
.modal-confirm .btn span {
	margin: 1px 3px 0;
	float: left;
}
.modal-confirm .btn i {
	margin-left: 1px;
	font-size: 20px;
	float: right;
}
.trigger-btn {
	display: inline-block;
	margin: 100px auto;
}
.modal.show .modal-dialog {
  transform: none;
  padding: 0px !important;
  width: 100%;
  margin-top: 0px;
  text-align: center;
}
.modal-confirm .modal-header {
  background: #3637F5;
  border-bottom: none;
  position: relative;
  text-align: center;
  margin: -20px -30px 0;
  // border-radius: 5px 5px 0 0;
  padding: 25px;
}

@media screen and (max-width: 1024px) {
  .modal-outer{
   width: 40%;
  }
}
@media screen and (max-width: 768px) {
  .modal-outer{
   width: 50%;
  }
}
@media screen and (max-width: 425px) {
   .modal-outer{
    width: 75%;
   }
}
@media screen and (max-width: 320px) {
  .modal-outer{
   width: 90%;
  }
}
.close{
  color: white !important;
  font-size: 44px !important;
  margin-right: 5px !important;
}

.loader-overly {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: rgb(0, 0, 0);
  height: 100%;
  z-index: 99999999;
  opacity: 0.9;
}

.loader {
  position: absolute;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 7px solid #0D56A4;
  border-right: 7px solid #fff;
  border-bottom: 7px solid #0D56A4;
  border-left: 7px solid #fff;
  width: 80px;
  height: 80px;
  animation: spin 2s linear infinite;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: calc(30% + 0px);
}

@-webkit-keyframes spin {
  0% {
      -webkit-transform: rotate(0deg);
  }

  100% {
      -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
      transform: rotate(0deg);
  }

  100% {
      transform: rotate(360deg);
  }
}
.toaster{
  display: flex;
  justify-content: end;
  margin-top: 20px;
}
.alert {
  width: 30%;
  padding: 12px;
  background-color: #048b1b;
  color: white;
  display: flex;
  justify-content: space-between;
}
.alert-error{
  width: 30%;
  padding: 10px;
  background-color: #a70505;
  color: white;
  display: flex;
  justify-content: space-between;
}

.closebtn {
  margin-left: 15px;
  color: white;
  font-weight: bold;
  float: right;
  font-size: 22px;
  line-height: 20px;
  cursor: pointer;
  transition: 0.3s;
}

// exit box 

.exit-intent-box{
  position: fixed;
  min-width: 340px;
  display: none;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
  border-bottom: solid 4px #0D56A4 !important;
  border: none;
  background-color: #eeeeee;
  padding: 30px;
  max-width: 600px;
  z-index: 2;
}
.bg-custom-dark{
  height: 100%;
  width: 100%;
  position: fixed;
  content: '';
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
}

.show{
  display: block !important;
}

.bold-text {
  font-weight: bold;
}

.large-text {
  font-size: 50px;
}

.blue-text {
  color: #0d56a4;
}

.block {
  display: block;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.margin-top-10 {
  margin-top: 10px;
}

.mt-5 {
  margin-top: 5px;
}

.exit-box-ul {
  list-style: none;
  padding-left: 20px;
}

.exit-box-ul li {
  position: relative;
  padding: 0 0 0 25px !important;
  margin-bottom: 10px;
}

.exit-box-ul li::before {
  content: "";
  position: absolute;
  top: 7px;
  left: 0;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #0d56a4; /* Customize the dot color */
}

// .exit-box-m{
//   background-color: #fff;
//   border: solid 1px #d4d4d4;
//   border-radius: 10px;
//   padding: 10px;
// }
