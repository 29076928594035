@import "../../../../../styles/style.scss";


@mixin envelopeIcon {
    svg {
        color: $color-accent;
        margin-right: 10px;
        font-size: 20px;
    }
}

@mixin textStyle {
    p {
        color: $color-main-dark;
        font-weight: bold;
        font-size: 18px;
    }
}

.alert__container {
    @extend .box-style;
    padding: 20px;
    margin-bottom: 16px;
    @media #{$break-md} {
        padding: 30px; 
      }
}

.alert__title {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    @include envelopeIcon();
    @include textStyle();
}

.alert__form {
    display: flex;
    align-items: center;
    flex-direction: column;

    @media #{$break-sm} {
        flex-direction: row;
    }

    button {
        width: 100%;
        height: 56px;
        text-transform: capitalize;
        font-weight: bold;
        white-space: nowrap;
        @extend .no-radius;
        font-size: 18px;

        svg {
            font-size: 20px;
            margin-right: 10px;
        }

        @media #{$break-sm} {
            width: 300px;
            margin-left: 8px;
            padding-left: 20px;
            padding-right: 20px;
        }
    }
}

.alert__description {
    margin: 10px 0;
    font-size: 16px;
}

.alert__success {
    @extend .flex-vertical-center;
    @include envelopeIcon();
    @include textStyle();
    padding: 20px;

    p { 
        margin-top: 5px;
        text-align: center;
    }
}

.alert__error {
    .errorMessage {
        margin-top: 8px !important;
        @media #{$break-md} {
         margin: 0 !important;
        }
    }
}