.advice_menu{
    padding: 200px 0px 100px 0px;
}
.advice_container{
    width: 80%;
    margin: auto;
}
.section1{
    margin: 50px 0px;
    display: flex;
}
.advice_head{
    width: 50%;
}
.advice_head h1{
    font-size: 100px;
    color: black;
    font-weight: 900;
}
.main_image_sec{
    background-color: #3637F5;
    margin: 170px 0px 50px 0px;
    width: 100%;
    height: 800px;
    display: flex;
    justify-content: end;
}
.main_image{
    position: relative;    
    width: 90%;
}
.main_image img{
    position: absolute;
    width: 100%;
    bottom: 65px;
}
.section2{
    text-align: center;
}
.sec2_head h1{
    font-size: 55px;
    font-weight: 700;
    color: black;
}
.pera{    
    margin-top: 60px;
}
.peragraph{
    width: 70%;
    margin: auto; 
}

.pera p {
    font-size: 20px;
    color: #000;
   
}
.section3{
    width: 90%;
    margin: auto;
    background-color: #f3f3f5;
    padding: 50px;
    margin-top: 50px;
}
.sec3_images{
    // margin-top: 50px;
    display: flex;
}
.newslatter{
    width: 50%;
    text-align: end;
    padding-right: 50px;
}
.blog_sec{
    width: 50%;
    text-align: start;
    padding-left: 50px;
}
.news_btn {
    position: relative;
}
.news_btn button{
    position: absolute;
    white-space: nowrap;
    bottom: -25px;
    right: -20px;
    background-color: #efececb8;
    border: 3px solid #3295eb;
    border-radius: 5px;
    padding: 25px 85px;
    box-shadow: var(--shd,0 1px 4px rgba(0,0,0,.6));
}
.news_btn button:hover{
    background-color: black;
    color: white;
}
.blog_btn {
    position: relative;
}
.blog_btn button{
    position: absolute;
    white-space: nowrap;
    bottom: -25px;
    left: 26px;
    background-color: #efececb8;
    border: 3px solid #3295eb;
    border-radius: 5px;
    padding: 25px 108px;
    box-shadow: var(--shd,0 1px 4px rgba(0,0,0,.6));
}
.blog_btn button:hover{
    background-color: black;
    color: white;
}
.section4{
    background-color: #f3f3f5;
    padding: 50px 0px;
    margin-top: 50px;
    
}
.subscribe{
    width: 60%;
    margin: auto;
    // margin: 20px 0px;
}
.subscribe_heading{
    text-align: center;
    margin: 20px 0px;
}
.subscribe_heading a{
    font-size: 40px;
    color: black;
    font-weight: 500;
    cursor: pointer;
}
.email_input{
    display: block;
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    box-sizing: border-box;
    border: none;
    border-bottom: 2px solid black;
}
.signup_btn{
    width: 100%;
    background-color: #3637F5;
    border: 1px solid #3637F5;
    color: white;
    padding: 15px 0px;
}
.signup_btn:hover{
    color: #3637F5;
    background-color: white;
}
.error{
    color: red;
}


@media screen and (max-width: 1024px) {
    .main_image_sec{
        height: 550px;
    }
}
@media screen and (max-width: 996px) {
    .advice_head h1{
        font-size: 80px;
    }
    .main_image img{
        height: 450px;
        width: 100%;
    }
    .section2{
        margin-top: 10px;
    }
    .pera{
        width: 90%;
    }
    .main_image_sec{
        height: 500px;
    }
}

@media screen and (max-width: 768px) {
    .advice_head h1{
        font-size: 70px;
    }
    .main_image img{
        width: 100%;
    }
    .pera{
        width: 100%;
    }
    .sec2_head h1{
        width: 95%;
        margin: auto;
        font-size: 40px;
    }
    .section2{
        width: 100%;
    }
    .sec3_images{
        display: block;
    }
    .newslatter{
        width: 100%;
        text-align: center;
    }
    .blog_sec{
        width: 100%;
        margin-top: 50px;
        text-align: center;
        padding-left: 0px;
    }
    .news_btn button{
        right: 100px;
    }
    .advice_menu{
        padding: 50px 0px;
    }
    .blog_btn button{
        left: 115px;
    }
    .main_image_sec{
        height: 350px;
    }
}
@media screen and (max-width: 425px) {
    .advice_menu{
        padding: 50px 0px;
    }
    .section1{
        margin-top: 0px;
    }
    .advice_head h1{
        font-size: 40px;
    }
    .main_image img{
        width: 100%;
        height: 360px;
    }
    .sec2_head h1{
        width: 95%;
        margin: auto;
        font-size: 40px;
    }
    .section2{
        margin-top: 30px;
    }
    .pera{
        margin-top: 30px;
    }
    .news_btn button{
        right: -30px;
        padding: 20px 88px;
    }
    .blog_btn button{
        left: 15px;
        bottom: -40px;
        padding: 25px 108px;
    }
    .main_image_sec{
        margin-top: 120px;
    }
}

@media screen and (max-width: 375px) {
    .news_btn button{
        right: -18px;
        padding: 20px 65px;
    }
    .newslatter img{
        width: 100%;
    }
    .newslatter{
        padding-right: 0px;
    }
    .blog_sec{
        padding-left: 0px;
    }
    .blog_sec img{
        width: 100%;
    }
    .blog_btn button{
        white-space: nowrap;
        left: 29px;
        padding: 25px 83px;
    }
}

@media screen and (max-width: 320px) {
    .news_btn button{
        padding: 20px 40px;
    }
    .blog_btn button{
        left: 29px;
        padding: 20px 60px;
        bottom: -25px;
    }
}