.profile{
    margin-top: 10px;
    padding: 20px 50px;
}
.profile_head{
    // background-color: #fff;
    text-align: center;
}
.heading_pro{
    color:#0D56A4;
    font-weight: 600;
}
.add_btn{
    margin-right: 5px;
    border: none;
    background-color: #0D56A4;
    color: white;
    padding: 7px 20px;
    border-radius: 8px;
    font-size: 18px;
    font-weight: 600;
    margin-top: 20px;
}
.add_more{
    display: flex;
    justify-content: end;
    margin-top: 20px;
}
.label{
    font-weight: 600;
}
.cross_btn{
    margin-top: 20px;
    color: #0D56A4;
    font-size: 20px;
}
.summary_border{
    border: 1px solid lightgray;
    padding: 10px;
}
.add_morebtn{
    margin-right: 5px;
    border: none;
    background-color: #0da465;
    color: white;
    padding: 5px 15px;
    border-radius: 8px;
    font-size: 18px;
    margin-top: 20px;
}

.modal_dialoge{
    display: flex !important;
    justify-content: center !important;
    text-align: center !important;
    // margin: auto !important;
}
.modal {
    display: flex !important;
    justify-content: center !important;
}