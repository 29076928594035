@import "../../../../../styles/style.scss";

.submit-vacancy__stepper {
    
}

.submit-vacancy__steps {
    margin: 0 auto;
    @media #{$break-md}{
        width: 70%;
    }
}

.steps__footer {
    margin-top: 40px;
}

/* Step 01
   ========================================================================== */

/* Step 02
   ========================================================================== */
.step02__jobtype {
    @extend .flex-center;
    margin-bottom: 30px;

    button {
        @extend .no-radius;
        width: 250px;
        height: 56px;
        margin: 0 4px;
        font-weight: bold;
        letter-spacing: 1px;

        &:active { //on checked
            background: $color-main;
            color: white;
        }
    }
}

/* Step Finish
   ========================================================================== */
.submit-vacancy__finished {
    @extend .flex-vertical-center;
    text-align: center;
    max-width: 600px;
    margin: 20px auto;
    h2 {
        color: $color-main-dark;
    }
    p {
        font-size: 18px;
    }
}

.MuiStepper-root {
    padding: 24px 0 24px 0 !important;
    @media #{$break-md}{
    }
}

.MuiStep-horizontal {
    padding: 0 !important;
}

.MuiStepLabel-label.MuiStepLabel-alternativeLabel {
    font-size: 14px;
    @media #{$break-md}{
        font-size: 18px;
    }
}
.MuiStepLabel-active, .MuiStepLabel-completed {
    color: $color-main !important;
}