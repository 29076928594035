/* Form Styles
   ========================================================================== */


// column style form (FWB, Registration, etc. )

.columnForm__wrapper {
  width: 100%;
  margin: 20px 0;

  @media #{$break-md} {
    display: flex;
    align-items: flex-start;
  }

  //title on the left
  .columnForm__title {
    width: 300px;
    font-size: 16px;
    margin-top: 8px;
    color: $color-main-dark;
    padding-bottom: 10px;

    @media #{$break-md} {
      text-align: right;
      font-size: 18px;
      margin-top: 12px;
      line-height: 24px;
      padding-right: 20px;
    }
  }

  //form on the right
  .columnForm__form {
    width: 100%;
    border-top: 1px solid #bdbdbd !important;
    padding: 10px 0 0 0;

    @media #{$break-md} {
      border-top: none !important;
      border-left: 1px solid #bdbdbd !important;
      padding: 0 0 0 20px;
    }
  }
}


.formfield-columns {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 10px;
  width: 100%;

  @media #{$break-md} {
    justify-content: start;
  }
}

.formfield-columns--fullWidth {
  width: 100%;

  @media #{$break-md} {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 10px;
  }
}

.file-upload {
  display: flex;
  align-items: center;

  label {
    width: 150px;
    height: 100%;
    margin-left: 10px;
  }
}

.fieldset-container {
  margin: 12px 0 !important;

  legend {
    font-weight: bold !important;
    margin: 8px 30px 8px 0;
    font-size: 16px;
    color: $color-black !important;
    line-height: 24px;
  }
  .fieldset-row {
    display: flex;
    width: 100%;
    align-items: center;
  }
}



.form-subsection {
  margin: 0 0 40px 0;
}

.form-title {
  margin-top: 20px;
  color: $color-main-dark;
}





/* Material Design Overrides
   ========================================================================== */

.MuiFormControl-root {
  margin: 8px 0 !important;

  &.small {
    width: 100% !important;

    @media #{$break-md} {
      width: 230px !important;
    }
  }

  &.tiny {
    width: 120px !important;
  }
}

.MuiInputBase-root {
  background-color: $color-main-light !important;
}

.MuiFormControlLabel-label {
  color: $color-black !important;
}

.errorMessage {
  width: 100%;
  text-align: left;
  // max-width: 600px;
  margin: 0 auto !important;
  line-height: 28px !important;
  a {
    color: inherit;
    text-decoration: underline;
  }
}