@import "../../styles/style.scss";

.kb-entry__container {
    height: 105px;
    margin: 0 auto;
    background: white;
    @extend .BigShadow;
    position: relative;
    width: 100%;
    @extend .card-borderbottom;

    display: flex;

    @media #{$break-sm} {
        display: block;
        height: 410px;
    }
}

.kb-entry__thumbnail {
    width: 100px;
    height: 100%;
    border-radius: 4px 0px 0 0;
    object-fit: cover;

    @media #{$break-sm} {
        width: 100%;
        height: 180px;
        border-radius: 4px 4px 0 0;
    }
}

.kb-entry__circle {
    display: none;

    @media #{$break-sm} {
        display: block;
        width: 55px;
        position: absolute;
        left: 10px;
        top: 157px;
    }

}

.kb-entry__panelbody {
    width: 100%;
    height: 100%;
    padding: 12px;
    color: $color-black;

    h5 {
        font-size: 16px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;

        @media #{$break-sm} {
            font-size: 20px;
        }
    }

    p {
        /* summary */
        display: none;

        @media #{$break-sm} {
            display: block;

            font-size: 14px;
            line-height: 20px;
            margin-top: 5px;
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
        }
    }

    @media #{$break-sm} {
        padding: 36px 16px 0px;
        height: 230px;
    }

    .author {
        color: $color-darkGrey;
    }
}

.kb-entry__panelbody-bottom {
    position: absolute;
    bottom: 10px;
    right: 16px;
    font-weight: bold;
    font-size: 14px;
    color: $color-darkGrey;

    .readtime {
        @extend .flex-horizontal-center;
    }
}