.container{
    width: 100%;
    display: flex;
    justify-content: center;
}
.outer{
    width: 40%;
    // margin: auto;
    // text-align: center;
    margin-top: 50px;
}
.overflow_hidden{
    // width: 730px !important;
    padding: 40px 40px 30px 40px !important;
    border-radius: 4px;
    // align-items: center;
    border: 1px solid lightgray;
}
.update_btn{
    margin-right: 5px;
    border: none;
    background-color: #0D56A4;
    color: white;
    padding: 6px 15px;
    border-radius: 8px;
    font-size: 16px;
    margin-top: 15px;
}
.footer{
    display: flex;
    justify-content: space-between;
}
.pass_icon{
    position: relative;
}
.eye_icon{
    position: absolute !important;
    top: -24px !important;
    right: 21px !important;
    font-size: 18px;
}
.footer{
    display: flex;
    justify-content: end;
}