.col-md-3 {
    padding-right: 0px !important;
}

.col-md-9 {
    padding-left: 0px !important;
}

.sidebar {
    background-color: #0D56A4;
    height: 100vh;
}

.main_icon{
    border-radius: 10px;
}
.a_link {
    text-decoration: none !important;
    color: #0D56A4;
    white-space: nowrap;
}

.sidebar_pic {
    width: 165px;
}

ul {
    list-style: none;
    border-radius: 10px;
  }
  

li {
    padding: 10px;
    cursor: pointer;
    // transition: background-color 0.3s, color 0.3s;
    // width: 200px;
  }

li.active {
    background-color: #0D56A4   ;
    color: white;
    border-radius: 0px 30px 30px 0px;
    margin-right: 25px;
}


@media screen and (max-width: 768px) {
    .sidebar_pic {
        width: 130px;
    }
}