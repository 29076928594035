@import "../../../styles/style.scss";

// overrides material design side menu
.MuiPaper-elevation1 {
    box-shadow: none !important;
}

.sidemenu.MuiPaper-root {
    border: 1px solid $color-lightGrey !important;
}


.MuiListItemIcon-root {
    min-width: 30px !important;
}

.MuiList-padding {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.MuiTypography-root {
    font-size: 16px;
}

.MuiMenuItem-root {
    padding-top: 12px !important;
    padding-bottom: 12px !important;

    &:hover {
        background: $color-main-light !important;
    }
}

.MuiListItemIcon-root {
    color: $color-main !important;
    font-size: 16px !important;
}

// custom css
.sidemenu {
    display: none;
    font-size: 16px !important;

    @media #{$break-md} {
        display: block;
        width: 230px;
    }

    .sidemenu__icon {
        font-size: 18px !important;
    }

    .sidemenu--noicon {
        min-width: unset !important;
    }

    a {
        color: $color-black;
    }

    a li {
        border-bottom: 1px solid $color-lightGrey !important;
    }

    a:last-child {
        li {
            border-bottom: none !important;
        }
    }
    .MuiMenuItem-root {
        &:hover {
            background: $color-main-light !important;
            color: $color-main !important;
        }
    }
    a.active {
        .MuiMenuItem-root {
            background: $color-main !important;
            color: white !important;

            .MuiListItemIcon-root {
                color: white !important;
            }
        }
    }

}

.sidemenu__after {
    @extend .btn-arrow-animation;
    @extend .custom-button;
    width: 100%;
    background: $color-main-dark !important;
    border:none !important;
    color: white !important;
    text-transform: capitalize !important;
    letter-spacing: 0;
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
    height: 50px;
    @media #{$break-md} {
        margin-top: 16px !important;
    }
}

/* Side menu - mobile
   ========================================================================== */

.side-menu--mobile {
    width: 100% !important;
    position: fixed;
    top: 50px;
    background-color: #0D56A4;
    font-size: 16px;
    color: white;
    z-index: 999;
    @extend .BigShadow;

    .side-menu--mobile__arrow {
        min-width: 16px !important;
    }

    .MuiPaper-root {
        border: none !important;
    }

    /* Material Design Overrides
   ========================== */
}

.side-menu--mobile__menuItem {
    .MuiPopover-paper {
        top: 95px !important;
        left: 0 !important;
        max-width: unset !important;
        width: 100% !important;
    }
}