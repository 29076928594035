@import "../../../styles/style.scss";

.file-upload-input {
    display: flex;
    width: 100%;
}

.file-upload-input--hide {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.file-upload-input--show {
    width: 100%;
    .MuiFormControl-root{
        width: 100%;
    }

    .MuiInputBase-root {
        cursor: pointer;
    }

    .MuiFilledInput-input { //overrides material ui input
        padding: 18.5px 12px;
        color:$color-darkGrey;
    }
}

.file-upload-label {
    margin: 8px 0 8px 10px;
    white-space: nowrap;
}