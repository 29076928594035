@import "../../styles/style.scss";

footer {
  border-top: 1px solid $color-lightGrey;
  background: white;
  color: $color-darkGrey;
  font-size: 14px !important;

  a {
    color: $color-darkGrey;

    &:hover {
      color: $color-main-dark;
    }
  }

  .footer--top__menu {
    text-transform: uppercase;
    font-weight: bold;
  }

  .footer--top {
    padding: 25px 20px 0px 20px;

    @media #{$break-md} {
      padding: 30px 0px 20px 0;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }

    .footer__logo {
      width: 80px;
      margin-bottom: 30px;

      @media #{$break-md} {
        width: 90px;
        margin-bottom: unset;
      }
    }

    .footer--top__content {
      @media #{$break-md} {
        display: grid;
        grid-template-columns: 35% 35% 30%;
      }
      @media #{$break-lg} {
        grid-template-columns: 38% 38% 24%;
      }
    }

    .footer--top__column {
      display: grid;
      grid-template-columns: 25% auto;
      grid-column-gap: 10px;
      padding: 16px 0;
      border-bottom: 1px solid $color-lightGrey;

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        border: none;
      }

      @media #{$break-md} {
        border-bottom: 0;
        border-right: 1px solid $color-lightGrey;
        grid-column-gap: 16px;
        padding: 0px 24px;

        &:first-child {
          padding-left: 0px;
        }

        &:last-child {
          padding-right: 0px;
          grid-template-columns: 35% auto;
        }
      }

      .footer--top__column__title {
        font-weight: bold;
        color: $color-main-dark;

        .footer__wcblogo {
          width: 100%;
          max-width: 100px;
        }

        .footer__hrslogo {
          width: 100%;
          max-width: 80px;
        }

        @media #{$break-md} {
          font-size: 16px;

          .footer__wcblogo,
          .footer__hrslogo {
            max-width: unset;
          }
        }
      }

      .footer--top__column__description {
        p {
          font-size: 14px !important;
          line-height: 20px;
        }
      }

      .footer--top__list {
        ul {
          display: grid;
          grid-template-columns: auto auto;
          grid-gap: 6px;

          @media #{$break-md} {
            grid-template-columns: 100%;
          }

          li {
            margin-bottom: 5px;
            cursor: pointer;

            svg,
            img {
              color: $color-main-dark;
              vertical-align: middle;
              margin-right: 6px;
              display: inline-block;

              @media #{$break-md} {
                display: inline-block;
              }
            }

            img {
              width: 16px;
            }

            span {
              vertical-align: middle;
            }

            @media #{$break-md} {
              font-size: 16px;
            }
          }

          .footer--top__sns {
            margin-top: 16px;
            grid-column: 1 / span 2;

            @media #{$break-sm} {
              grid-column: unset;
            }

            svg {
              color: $color-main-dark !important;
              font-size: 16px !important;
              margin-right: 12px !important;
              vertical-align: middle !important;
              display: inline-block !important;
            }

            @media screen and (min-width: 1400px) {
              display: none !important;
            }
          }
        }
      }

      .footer--top__contact {

        input,
        textarea {
          font-family: inherit;
          background-color: $color-main-light;
          font-weight: bold;
        }

        button {
          @extend .btn-primary--small;
          text-transform: uppercase;
          float: right;
        }
      }
    }

  }

  .footer--bottom {
    border-top: rgba(102, 102, 102, 0.2) 1px solid;
    font-size: $font-size-p4;
    padding: 20px;

    @media #{$break-md} {
      font-size: $font-size-p3;
    }

    .footer--bottom__menu {
      margin-bottom: 10px;

      @media #{$break-md} {
        margin-bottom: 0px;
      }
    }

    .footer--bottom__right {
      @extend .flex-horizontal-center;
      align-items: center;
      flex-wrap: wrap;

      .footer--bottom__designed {
        padding-left: 7px;

        span {
          vertical-align: middle;
        }

        img {
          width: 60px;
          margin-left: 5px;
          vertical-align: middle;

          @media #{$break-md} {
            width: 76px;
            margin-left: 7px;
          }
        }
      }
    }

  }

  .footer--bottom-flex {
    @extend .flex-vertical-center;

    @media #{$break-md} {
      flex-direction: row;
      justify-content: space-between;
    }
  }
}