@import "../../../../styles/style.scss";

.profile-panel {
  background: white;
  @extend .BigShadow;
  @extend .card-borderbottom;
  margin: 0 auto;
  width: 100%;
  max-width: 300px;
  height: 336px;

  img {
    width: 100%;
    height: 240px;
    object-fit: cover;
    object-position: top;
    border-radius: 4px 4px 0 0;
  }
}

.profile-panel__body {
  padding: 10px 16px;

  p {
    // employee name    
    font-size: 16px;
    line-height: 18px;
    font-weight: bold;
    color: $color-black;
  }
}

.profile-panel__footer {
  height: 32px;

  div {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    height: 100%;

    p {
      // employee role
      font-size: 14px;
      margin: 0;
      color: $color-main;
    }

    a {
      // linkedin
      width: 18px;
      height: 18px;

      svg {
        font-size: 18px;
        color: $color-main;
      }
    }
  }
}