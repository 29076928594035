.profile {
  margin-top: 10px;
  padding: 20px 50px;
}
.profile_head {
  text-align: center;
}
.heading_pro {
  color: #0d56a4;
  font-weight: 600;
}
.label{
  font-weight: 600;
}
// .add_btn {
//   margin-right: 5px;
//   border: none;
//   background-color: #0d56a4;
//   color: white;
//   padding: 7px 20px;
//   border-radius: 8px;
//   font-weight: 600;
//   font-size: 18px;
// }
.modal {
  width: 100%;
  display: flex;
  justify-content: center;
}
.add_morebtn{
  margin-right: 5px;
  border: none;
  background-color: #0da465;
  color: white;
  padding: 5px 15px;
  border-radius: 8px;
  font-size: 18px;
  margin-top: 20px;
}
.profile{
  margin-top: 10px;
  padding: 20px 50px;
}
.profile_head{
  text-align: center;
}
.heading_pro{
  color: #0D56A4;
  font-weight: 600;
}
.add_btn{
  margin-right: 5px;
  border: none;
  background-color: #0D56A4;
  color: white;
  padding: 7px 20px;
  border-radius: 8px;
  font-size: 18px;
  margin-top: 20px;
}
.add_more{
  display: flex;
  justify-content: end;
  margin-top: 20px;
}
.cross_btn{
  margin-top: 20px;
  color: #0D56A4;
  font-size: 20px;
}
.summary_border{
  border: 1px solid lightgray;
  padding: 10px;
}
.modal{
  width: 100%;
  display: flex;
  justify-content: center;
}