/* App Colors */
$color-main: #0D56A4;
$color-main-dark: #17306A;
$color-main-light: #F7F9FA;
$color-accent: #FCBA51;
$color-black: #2b3135;
$color-titleGrey: #4f4f4f;
$color-darkGrey: #666666;
$color-grey: #949799;
$color-lightGrey: #DDDDDD;
$color-veryLightGrey: #F7F7F7;

/* Status Colors */
$color-error: #912D2B;
$color-success: #19521B;

/* Others */
$color-background-dim: #BD7372;

/* Fonts */
$font-family-primary: "Lato", "Helvetica", sans-serif !important;

/* Font Sizes */
$font-size-h1: 40px;
$font-size-h2: 35px;
$font-size-h3: 30px;
$font-size-h4: 25px;
$font-size-h5: 20px;
$font-size-p1: 18px;
$font-size-p2: 16px;
$font-size-p3: 14px;
$font-size-p4: 12px;
