@import "../../../../styles/style.scss";

.dialogStyle {
    text-align: center;
}

.center {
    margin: 0 auto;
}

.dialogTitle {
    padding: 40px 50px 0px 50px;
    color: $color-main-dark;
}

.dialogContentText {
    font-size: 18px !important;
    color: $color-black !important;
    padding-top: 0px !important;
}

.dialogActions {
    margin: 0 auto;
}