@import "../../../styles/style.scss";

$padding: 20px 35px;

.jobsearch {
  @extend .flex-vertical-center;
  width: 100%;

  .jobsearch__top {
    width: 100%;
    .jobsearch__filters {
      @media #{$break-md} {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;

        .jobsearch__top__inputDiv {
          width: 100%;
          margin: 0 8px 0 0;
          .input-label {
            font-size: 14px;
            width: 90%;
            @media #{$break-lg} {
              font-size: 16px;
            }
          }
        }
      }
    }

    .label {
      font-size: 16px;
      color: white;
    }

    .jobsearch__top__button {
      height: 55px;
      width: 100%;

      margin-bottom: 8px !important;
      padding-left: 12px !important;
      padding-right: 12px !important;
      @media #{$break-lg} {
        max-width: 190px;
        padding-left: 30px !important;
        padding-right: 30px !important;
      }

      span {
        font-size: 16px;
        @media #{$break-lg} {
          font-size: 18px;
        }
      }

      svg {
        margin-left: 10px;
      }
    }
  }

  .jobsearch__bottom {
    width: 100%;
    padding: $padding;

    border-top: 1px solid rgba(255, 255, 255, 0.3);

    .jobsearch__filter-items {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;

      .jobsearch__filter-item {
        padding-right: 20px;
        color: white;
        font-size: 18px;
        display: flex;
        align-items: center;
        cursor: pointer;
        svg {
          margin-left: 5px;
          vertical-align: middle;
        }

        @media #{$break-md} {
          padding-right: 30px;
        }
      }
    }

    .jobsearch__filter-items--open {
      padding-top: 20px;
      display: flex;
      align-items: center;
      width: 100%;
    }
  }

  .jobsearch__clearFilters {
    @extend .flex-center;
    margin: 10px auto;
    button {
      border-color: white !important;
      color: white !important;
      width: 100%;
    }
    @media #{$break-md} {
      justify-content: flex-start !important;
      button {
        width: unset !important;
      }
    }
  }
}
