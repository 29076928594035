@import "../../styles/style.scss";

.pagination_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

@mixin button() {
  padding: 12px 16px;
  background: none;
  border: none;
  font-family: $font-family-primary;
  font-size: 16px;
}

.button-grouping {
  display: flex;
  span {
    @include button();
    margin: 10px !important;
  }
}

.button {
  @include button();
  background: white;
  &:hover {
    color: $color-main;
    background: $color-main-light;
  }
}

.button_active {
  @include button();
  background: $color-main;
  color: white;
}
