@import "../../styles/style.scss";


$circle-size: 140px;

$check-height: $circle-size/2;
$check-width: $check-height/2;
$check-left: $circle-size/4.5;
$check-thickness: $circle-size/13;

.checkmark-circle {
  width:$circle-size;
  height:$circle-size;
  position: relative;
  display: inline-block;
  vertical-align: top;

  .background {
    width: $circle-size;
    height: $circle-size;
    border-radius: 50%;
    background: $color-main;
    position: absolute;
    @extend .BigShadow;
  }

  .checkmark {  

    &.draw:after {
      animation-delay:80ms;
      animation-duration:0.6s;
      animation-timing-function:ease;
      animation-name:checkmark;
      transform: scaleX(-1) rotate(180deg + -45deg);
      animation-fill-mode :forwards;
    }

    border-radius: 5px;

    &:after {
      opacity: 1; 
      height: $check-height; 
      width: $check-width;
      transform-origin: left top;
      border-right: $check-thickness solid white;
      border-top: $check-thickness solid white; 
      border-radius: 2.5px !important;
      content: '';
      left: 25%;
      top: 80px;
      transform: translateX(-50%);
      position: absolute; 
    }

  }

}

@keyframes checkmark {
  0% { height: 0; width: 0; opacity: 1; }
  20% { height: 0; width: $check-width; opacity: 1; }
  40% { height: $check-height; width: $check-width; opacity: 1; }
  100% { height: $check-height; width: $check-width; opacity: 1; }
}

@-webkit-keyframes checkmark {
  0% { height: 0; width: 0; opacity: 1; }
  20% { height: 0; width: $check-width; opacity: 1; }
  40% { height: $check-height; width: $check-width; opacity: 1; }
  100% { height: $check-height; width: $check-width; opacity: 1; }
}