.body__article {
    background-color: #fff;
}
// .ja-jobs-widget {
//     margin-top: 30px !important;
// }
.ja-job-details {
    margin: 30px 30px;
    width: 94%;
    margin-bottom: 30px;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    -webkit-flex-grow: 1;
    transition: all .3s ease;
    background: #fff;
    padding: 20px;
    font-size: 14px;
    margin-right: 22px;
    border: 1px solid #dddfe3;
    border-top: 6px solid #0d56a4;
}
.title {
    font-size: 16pt;
    font-weight: 500;
    color: #05758a;
    line-height: 1.3;
    letter-spacing: .3pt;
    margin-bottom: 15px;
}
.classifications {
    padding-left: 8px;
    list-style: none;
}
.classifications li {
    font-family: urw-din,sans-serif;
    font-weight: 400;
    line-height: 1;
    letter-spacing: .75pt;
    margin: 8px 0;
    display: block;
    font-size: 13pt;
    color: #333;
}
.date-posted {
    font-size: 9pt;
    color: #000;
    padding-left: 8px;
    font-family: urw-din,sans-serif;
    font-weight: 400;
    line-height: 1;
    letter-spacing: .75pt;
    margin: 8px 0;
    display: block;
}
.description {
    font-family: urw-din,sans-serif;
    font-size: 12pt;
    font-weight: 400;
    color: #333;
    line-height: 1;
    letter-spacing: .75pt;
    margin: 8px 0;
    display: block;
    padding-left: 6px;
}
.apply {
    padding-left: 7px;
}
.back-link{
    color: #4183c4;
    text-decoration: none;
    cursor: pointer;
    background-color: transparent;
    border: none;
}
.back-links{
    color: #4183c4;
    text-decoration: none;
    cursor: pointer;
    background-color: transparent;
    border: none;
}
.ja-button {
    float: right;
    background: #0d56a4;
    height: 39px;
    text-align: center;
    color: #fff!important;
    height: 41px;
    padding: 10px;
    border: 1px solid #0d56a4;
    margin-bottom: 20px;
}
#form-wrapper {
    width: 500px;
    padding: 0;
    margin: 0 auto -50px;
}
#apply-method {
    border-bottom: solid 1px #eee;
    margin: 20px 0 15px;
    padding-bottom: 14px;
}
#apply-resume {
    float: left;
    max-width: 47%;
}
#form .btn-file {
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
}
#form .btn.full-width {
    width: 100%;
}
#form .btn {
    display: inline-block;
    padding: 8px 30px;
    margin-bottom: 0;
    margin-right: 4px;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.42857143;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    color: #444;
    background-color: #fff;
    border: 1px solid #dbdbdb;
    border-radius: 3px;
    white-space: nowrap;
    user-select: none;
}
#apply-resume input {
    max-width: 210px;
    overflow: hidden;
}
#CandidateResume{
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 999px;
    text-align: right;
    opacity: 0;
    cursor: inherit;
    display: block;
}
#apply-or {
    float: left;
    max-width: 6%;
    margin: 0 20px;
    text-align: center;
}
#apply-or span {
    color: #aaa;
    font-weight: bold;
    line-height: 1;
    display: inline-block;
    padding: 7px;
    margin-top: 4px;
    background: #f9f9f9;
    border: solid 1px #e1e1e1;
    border-radius: 25px;
}
#apply-later {
    float: left;
    max-width: 47%;
}
#btnApplyLater{
    width: 100%;
    display: inline-block;
    padding: 8px 30px;
    margin-bottom: 0;
    margin-right: 4px;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.42857143;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    color: #444;
    background-color: #fff;
    border: 1px solid #dbdbdb;
    border-radius: 3px;
    white-space: nowrap;
}
.form-field {
    margin-bottom: 15px;
}
#form .form-field.two-cols .col {
    float: left;
    width: 49%;
}
// .form-field.two-cols .col {
//     float: left;
//     width: 49%;
// }
.phone-number{
    display: flex !important;
}
.form-field label {
    display: inline-block;
    margin-bottom: 5px;
}
.form-field .form-control {
    display: block;
    width: 100%;
    height: 38px;
    // padding: 2px 10px;
    color: #444;
    background-color: #fff;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 3px;
}

form .form-field.two-cols .col:last-child {
    float: right;
}
.form-field .field-validation-valid {
    display: none;
}

.phone-number {
    overflow: visible;
    display: flex !important;
}
.phone-number-country-list{
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
}

.flex-row {
    display: flex !important;
}
.country-list {
    width: 60px!important;
    margin-right: 2%!important;
    box-sizing: border-box;
    margin: 0;
    position: relative;
    display: inline-block;
    zoom: 1;
    vertical-align: middle;
}
.select2-choice {
    display: block;
    height: 26px;
    padding: 0 0 0 8px;
    overflow: hidden;
    position: relative;
    border: 1px solid #aaa;
    white-space: nowrap;
    line-height: 26px;
    color: #444;
    text-decoration: none;
    border-radius: 4px;
    background-clip: padding-box;
    user-select: none;
    background-color: #fff;   
    background-image: linear-gradient(to top,#eee 0%,#fff 50%);
}
.select2-offscreen, .select2-offscreen:focus {
    clip: rect(0 0 0 0)!important;
    width: 1px!important;
    height: 1px!important;
    border: 0!important;
    margin: 0!important;
    padding: 0!important;
    overflow: hidden!important;
    position: absolute!important;
    outline: 0!important;
    left: 0!important;
    top: 0!important;
}
.formatted-number {
    flex-grow: 1!important;
}
.phone-number-validation {
    margin-top: 8px;
}
.warning {
    color: #f08c00!important;
}
.form-field.no-margin {
    margin-bottom: 0;
}
.visible-mobile{
    display: inline-block;
    margin-bottom: 5px;
}
.check-inline {
    display: block;
    min-height: 20px;
    margin-bottom: 10px;
}
form .form-field .field-validation-valid {
    display: none;
}
.select2-container .select2-choice>.select2-chosen {
    margin-right: 26px;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    float: none;
    width: auto;
}
.mr-2 {
    margin-right: 2%!important;
}
.country-list {
    width: 60px!important;
}
.select2-container {
    margin: 0;
    position: relative;
    display: inline-block;
    zoom: 1;
}
.form-field textarea.form-control {
    display: block;
    width: 100%;
    height: 38px;
    padding: 2px 10px;
    color: #444;
    background-color: #fff;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 3px;
    padding: 8px 10px;
}
.hide {
    display: none;
}
#apply-button {
    margin-top: 25px;
}
#btnSubmit{
    background-color: #e8e8e8;
    cursor: pointer;
    display: inline-block;
    padding: 8px 30px;
    margin-bottom: 0;
    margin-right: 4px;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.42857143;
    text-align: center;
    color: #444;
    border-radius: 3px;
}
.modal {
    overflow: hidden;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    outline: 0;
}
.modal-dialog {
    position: relative;
    width: auto;
    margin: 10px;
}
.modal-content {
    position: relative;
    background-color: #fff;
    border-radius: 10px;
    background-clip: padding-box;
    outline: 0;
}
.modal-header {
    height: 35px;
    padding: 10px 10px 0;
}
.close {
    padding: 0;
    cursor: pointer;
    background: transparent;
    border: 0;
    margin-top: -2px;
}
.modal-body {
    position: relative;
    padding: 0 20px 10px;
}
.modal-body h1 {
    text-align: center;
    color: #333;
    margin: 0 0 10px;
    line-height: 1.42857143;
}
.modal-body p {
    margin: 0 0 10px;
}
.form-field {
    margin-bottom: 15px;
}
.form-field label {
    display: inline-block;
    margin-bottom: 5px;
}
// .form-control {
//     display: block;
//     width: 100%;
//     height: 38px;
//     padding: 2px 10px;
//     color: #444;
//     background-color: #fff;
//     box-sizing: border-box;
//     border: 1px solid #ccc;
//     border-radius: 3px;
//     // box-shadow: 0 0 1px rgb(0 0 0 / 8%);
// }
.field-validation-valid {
    display: none;
}

.success {
    display: none;
}
.success h1 {
    text-align: center;
}
.success p {
    text-align: center;
}
.modal-footer {
    text-align: center;
    padding: 0 20px 20px;
}
.modal-footer button{
    background-color: #e8e8e8;
    display: inline-block;
    padding: 8px 30px;
    margin-bottom: 0;
    margin-right: 4px;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.42857143;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    color: #444;
    border: 1px solid #dbdbdb;
    border-radius: 3px;
    white-space: nowrap;
}
.cancel{
    display: inline-block;
    padding: 8px 30px;
    margin-bottom: 0;
    margin-right: 4px;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.42857143;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    color: #444;
    background-color: #fff;
    border: 1px solid #dbdbdb;
    border-radius: 3px;
    white-space: nowrap;
}