@import "../../../../styles/style";

.wrapper {
  padding: 16px;
}
.container {
  width: 100%;
  padding: 16px;
  display: grid;
  grid-gap: 10px;
  article {
    order: 2;
  }
  aside {
    order: 1;
  }

  @media #{$break-md} {
    grid-template-columns: 68% 30%;
    grid-gap: 2%;

    article {
      order: 1;
    }
    aside {
      order: 2;
    }
  }

  .jobDetails {
    @extend .box-style;
    margin-bottom: 20px;
    h4 {
      color: $color-main;
      margin-bottom: 20px;
    }
  }
  .jobDetails_apply {
    button {
      width: 100%;
      @media #{$break-md} {
        width: 300px;
        display: flex;
        justify-content: center;
        margin: 0 auto;
      }
    }
  }
  .actions {
    button {
      width: 100%;
      height: auto;
      margin-bottom: 8px;
    }
  }
  hr {
    margin: 20px 0 16px 0;
    border: 0.5px solid $color-lightGrey;
  }
  .overview {
    @extend .box-style;
    padding: 20px;
    font-size: 16px;
    @media #{$break-md} {
      margin-top: 0;
    }
    div {
      margin: 8px 0;
    }
    svg {
      vertical-align: text-bottom;
      color: $color-grey;
    }
    .overview__moreDetail {
      div {
        width: 100%;
        display: grid;
        grid-template-columns: 60% 40%;
      }
    }
  }
}

.job_body{
  margin-top: 30px;
}
.ja_form {
  display: flex;
  width: 94%;
  margin: 0 auto;
  flex-wrap: wrap;
}
.ja_field_container {
  margin-right: 15px;
  width: 16.53%;
}
.ja_field label, .ja_form .ja_field_container label {
  display: none;
}
.ja_field select {
  font-size: 10pt;
  letter-spacing: 1.5px;
  line-height: 9.8;
  color: #15345b;
  height: 41px;
  padding: 10px;
  width: 100%;
}

.ja_button {
  float: right;
}

.ja_button {
  background: #0d56a4;
  height: 39px;
  text-align: center;
  color: #fff!important;
  height: 41px;
  padding: 10px;
  border: 1px solid #0d56a4;
}
.ja_field input {
  font-size: 10pt;
  letter-spacing: 1.5px;
  line-height: 9.8;
  color: #15345b;
  height: 41px;
  padding: 10px;
}
.ja-job-list {
  display: flex !important;
  margin: 30px auto 0 !important;
  width: 100% !important;
  flex-wrap: wrap !important;
}

// .ja-job-list {
//   display: flex !important;
//   margin: 30px auto 0 !important;
//   width: 100% !important;
//   flex-wrap: wrap !important;
// }

.job {
  width: 31% !important;
  margin-bottom: 30px !important;
  border-radius: 2px !important;
  display: flex !important;
  max-width: 263px !important;
  flex-direction: column !important;
  flex-grow: 1 !important;
  -webkit-flex-grow: 1 !important;
  transition: all .3s ease !important;
  background: #fff !important;
  padding: 20px !important;
  font-size: 14px !important;
  margin-right: 22px !important;
  margin-left: 22px !important;
  border: 1px solid #dddfe3 !important;
  border-top: 6px solid #0d56a4 !important;
}
.title {
  font-size: 16pt;
  font-weight: 500;
  color: #05758a;
  line-height: 1.3;
  letter-spacing: .3pt;
  margin-bottom: 15px;
}
.classifications {
  padding-left: 8px;
  list-style: none;
}
.date-posted {
  font-size: 9pt;
  color: #000;
  padding-left: 8px;
}
.date-posted, ul.classifications li {
  font-family: urw-din,sans-serif;
  font-weight: 400;
  line-height: 1;
  letter-spacing: .75pt;
  margin: 8px 0;
  display: block;
}
.summary {
  font-size: 13pt;
  color: #333;
  line-height: 1;
  margin: 8px 0;
  display: block;
  padding-left: 5px;
}
.view-details {
  font-size: 10pt;
  color: #05758a;
  padding-left: 8px;
  text-align: end;
  border: none;
  background-color: transparent;
}

.view-details, .summary {
  font-family: urw-din,sans-serif;
  font-weight: 400;
  letter-spacing: .75pt;
}

.powered-by-jobadder a  {
  cursor: pointer;
  color: #4183c4;
  text-decoration: none;
}

.ja-job-list {
  display: flex;
  margin: 30px auto 0;
  width: 100%;
  flex-wrap: wrap;
}

// li {
//   color: green !important;
// }

.ja-job-list-container .ja-job-list {
  color: pink !important;
}


p.date-posted {
  color: pink;
}