.advice_container {
  width: 60%;
  margin: auto;
  display: flex;
}
.heading_sec {
  width: 60%;
  margin: auto;
  text-align: center;
  padding: 0px 40px;
}
.form_sub {
  width: 50%;
  // margin: auto;
  padding: 50px 40px;
  display: flex;
  align-items: center;
  background-color: #f9f9f9;
}
.advice_menu {
  padding: 50px 0px 60px 0px;
}
.form_inputs {
  width: 90%;
}
.modal_body{
  display: flex;
  justify-content: center;
  align-items: center;
}
.thank_msj {
  font-size: 16px;
}

.advice_container {
  background-color: #e7e6e6;
  // padding: 20px;
  margin-top: 60px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.cover_image {
  width: 50%;
  margin: auto;
  padding: 50px;
}
.form_title {
  text-align: center;
  margin-bottom: 20px;
  color: black;
}
.cover_images {
  padding: 50px;
}
.heading {
  text-align: center;
  font-weight: 600;
  font-size: 40px;
  color: black;
  margin: 20px 0px;
}
.sub_heading {
  font-weight: 600;
  font-size: 22px;
  // text-align: center;
  word-spacing: 5px;
}
.sub_heading2 {
  font-size: 22px;
  margin-top: 40px !important;
}

.form_group {
  margin-bottom: 20px;
}

.label {
  display: block;
  margin-bottom: 5px;
}

.input_field {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}
.main_heading {
  width: 60%;
  margin: auto;
  padding: 20px 0px;
}
.error_message {
  color: red;
  font-size: 14px;
}

.submit_button {
  background-color: #0d56a4;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
}

.submit_button:hover {
  background-color: #0056b3;
}
.thank_outer {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container_thank {
  max-width: 500px;
  margin: 50px auto;
  background-color: #f9f9f9;
  border-radius: 8px;
  // box-shadow: 0 0 10px #8bc34a;
  box-shadow: 0 0 10px #17306a;
}
.thank_sec {
  // background: #8bc34a;
  background: #17306a;
  padding: 20px 0px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  // margin: 20px 0px;
}
.heading_thank {
  font-size: 2rem;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
  color: white;
  font-size: 30px;
  font-weight: 600;
}

.paragraph_thank {
  font-size: 1.2rem;
  color: #666;
  line-height: 1.5;
  text-align: center;
}
.thank_pera {
  padding: 50px;
  text-align: center;
}
.image_cover {
  width: 100%;
}
.logo_image {
  width: 160px;
  height: 80px;
  margin-bottom: 20px;
}
.continue_thank {
  // background: #8bc34a;
  background: #17306a;
  padding: 7px 15px;
  border-radius: 10px;
  font-weight: 600;
  font-size: 18px;
  color: white;
  border: none;
}
/* Additional styles for a more beautiful appearance */

.modal_outer {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.modal_title {
  font-weight: 700;
  color: white;
}
// .modal_head {
//   text-align: center;
//   display: flex;
//   align-items: flex-start;
//   justify-content: center !important;
//   padding: 1rem 1rem 3rem !important;
//   border-bottom: 1px solid #dee2e6;
//   border-top-left-radius: calc(0.3rem - 1px);
//   border-top-right-radius: calc(0.3rem - 1px);
// }
.footer_text{
  text-align: left;
}
.footer_text p{
  font-size: 11px;
  line-height: normal;
}


@media (max-width: 768px) {
  .container_thank {
    padding: 20px;
  }
}

@media screen and (max-width: 768px) {
  .advice_container {
    display: block;
    margin-top: 10px;
  }
  .heading {
    font-size: 30px;
  }
  .main_heading {
    width: 90%;
    margin: auto;
  }
  .heading_sec {
    width: 90%;
    margin: auto;
    padding: 40px 40px;
  }
  .form_sub {
    width: 95%;
    margin: auto;
  }
  .cover_image {
    width: 100%;
    margin: auto;
    padding: 10px;
  }
}

@media screen and (max-width: 430px) {
  .advice_container {
    display: block;
  }
  .cover_image {
    width: 100%;
    margin: auto;
    padding: 10px;
  }
  .heading {
    font-size: 20px;
  }
  .heading_sec {
    width: 95%;
    margin: auto;
    padding: 40px 5px;
  }
  .form_sub {
    width: 90%;
    margin: auto;
  }
  .sub_heading {
    font-size: 18px;
  }
  .sub_heading2 {
    font-size: 18px;
  }
}

@media screen and (max-width: 375px) {
  .sub_heading {
    font-weight: 600;
    font-size: 16px;
    word-spacing: 2px;
  }
  .sub_heading2 {
    font-size: 16px;
  }
}
